import { ReactNode, useEffect, useMemo } from "react";

import dialogAPI from "operations/dialog";

import globalClasses from "styles/classes.module.scss";

import classes from "./styles/classes.module.scss";

const Dialog = () => {
  const dialogs = dialogAPI.select(state => state);
  const { alerts, fullscreenDialogs } = useMemo(() => {
    const alerts: ReactNode[] = [];
    const fullscreenDialogs: ReactNode[] = [];

    for (const [id, { node, type }] of dialogs) {
      switch (type) {
        case "alert":
          alerts.push(node);
          break;

        case "fullscreen":
          fullscreenDialogs.push(node);
          break;

        case "inline":
          fullscreenDialogs.push(<div id={`portal-${id}`} key={id} />);
          break;
      }
    }

    return { alerts, fullscreenDialogs };
  }, [dialogs]);

  useEffect(() => {
    if (fullscreenDialogs.length) {
      document.body.classList.add(globalClasses.overflowHidden);
    } else {
      document.body.classList.remove(globalClasses.overflowHidden);
    }
  }, [fullscreenDialogs.length]);

  return (
    <>
      <div>{fullscreenDialogs}</div>
      {alerts.length > 0 && (
        <div className={classes.alertsWrapper}>{alerts}</div>
      )}
    </>
  );
};

export default Dialog;
