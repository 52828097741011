import { FC } from "react";

import MenuIcon from "icons/menu.svg?react";

import { BlankButton } from "basics/button";

import { openProfileDrawer } from "modules/profile-drawer";

import classes from "./styles/classes.module.scss";

interface Props {
  isLoadedAccount: boolean;
}

const MobileButton: FC<Props> = ({ isLoadedAccount }) => {
  if (!isLoadedAccount) {
    return <div className={classes.mobileSkeleton} />;
  }

  return (
    <BlankButton
      className={classes.profileMobileButton}
      onClick={() => openProfileDrawer()}
    >
      <MenuIcon className={classes.menuIcon} />
    </BlankButton>
  );
};

export default MobileButton;
