import useDispatch from "hooks/redux/use-dispatch";

import { accountLoadScenario } from "operations/scenaries";

import { accountThunks } from "store/account";
import { addressesThunks } from "store/addresses";
import { currenciesThunks } from "store/currencies";

export const useConnect = () => {
  const dispatch = useDispatch();

  return {
    loadAddresses: () => dispatch(addressesThunks.loadAddresses()),
    loadPaymentMethods: () => dispatch(accountThunks.loadPaymentMethods()),
    loadCurrencies: () => dispatch(currenciesThunks.loadCurrencies()),
    accountLoadScenario: () => dispatch(accountLoadScenario()),
  };
};
