import { FC, ReactNode, useEffect } from "react";
import { useNavigate } from "@remix-run/react";

import { watcher } from "store";

import Header from "./components/header";

import Template from "./Template";

import { useConnect } from "./duck/hooks";

interface Props {
  children: ReactNode;
}

const AuthTemplate: FC<Props> = ({ children }) => {
  const { loadCurrencies, accountLoadScenario } = useConnect();
  const navigate = useNavigate();

  useEffect(() => {
    window.extensionScripts.forEach(script => {
      document.documentElement.appendChild(script);
    });

    let accountUnsubscribe;

    loadCurrencies();

    accountLoadScenario().then(() => {
      accountUnsubscribe = watcher.startListening({
        predicate: (
          _action,
          { account: { data } },
          { account: { data: prevData } },
        ) => data?.email !== prevData?.email,
        effect: () => {
          navigate(
            {
              pathname: location.pathname,
              search: location.search,
            },
            {
              replace: true,
              preventScrollReset: true,
            },
          );
        },
      });
    });

    return () => {
      accountUnsubscribe();
    };
  }, []);

  return (
    <Template>
      <Header />
      {children}
    </Template>
  );
};

export default AuthTemplate;
