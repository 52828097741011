import { forwardRef } from "react";
import joinClassNames from "classnames";

import { TextareaProps } from "./duck/types";

import classes from "./styles/classes.module.scss";

import BlankInput from "./components/blank-input";

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, classNames = {}, rows = 5, ...props }, ref) => (
    <BlankInput
      innerRef={ref}
      as="textarea"
      className={joinClassNames(classes.textareaFocusWrapper, className)}
      classNames={{
        ...classNames,
        wrapper: joinClassNames(classes.textareaWrapper),
        input: joinClassNames(classes.textarea),
      }}
      {...props}
      rows={rows}
    />
  ),
);

export default Textarea;
