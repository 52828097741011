import isObject from "lodash/isObject";

import { DataType, LabelValue } from "./types";

export const getTypedValue = (value: DataType): LabelValue => {
  if (isObject(value)) {
    return value;
  }

  return {
    label: value,
    value,
  };
};
