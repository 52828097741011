import { FC } from "react";
import joinClassNames from "classnames";

import classes from "./styles/classes.module.scss";

interface Props {
  className?: string;
}

const ChatSkeleton: FC<Props> = ({ className }) => (
  <div className={joinClassNames(classes.chatSkeleton, className)}>
    <div className={classes.icon} />
    <div className={classes.chatWrapper}>
      <div className={classes.title} />
      <div className={classes.description} />
    </div>
  </div>
);

export default ChatSkeleton;
