import { useEffect } from "react";

import useSelector from "hooks/redux/use-selector";

import { openFeedbackModal } from "modules/feedback-modal";

const FeedbackModalSync = () => {
  const transactionInfo = useSelector(state => state.storage.transactionInfo);

  useEffect(() => {
    if (transactionInfo) {
      openFeedbackModal(transactionInfo);
    }
  }, []);

  useEffect(() => {
    if (!transactionInfo) {
      return;
    }

    const callback = () => {
      if (document.visibilityState === "visible") {
        openFeedbackModal(transactionInfo);
      }
    };

    document.addEventListener("visibilitychange", callback);

    return () => {
      document.removeEventListener("visibilitychange", callback);
    };
  }, [transactionInfo]);

  return null;
};

export default FeedbackModalSync;
