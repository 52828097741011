import { Link, useNavigation } from "@remix-run/react";

import LogoIcon from "icons/logo.svg?react";

import { LineLoader } from "basics/loader";

import { Pages } from "constants/pages";

import useWindowSizes from "hooks/use-window-sizes";
import useSelector from "hooks/redux/use-selector";

import { urlSelector } from "selectors/url";

import Buttons from "./components/buttons";
import MobileButton from "./components/mobile-button";

import classes from "./styles/classes.module.scss";

const Header = () => {
  const isLoadedAccount = useSelector(state => state.account.isLoaded);
  const { isMobileWidth } = useWindowSizes();
  const { state } = useNavigation();

  return (
    <header className={classes.wrapper}>
      {state === "loading" && <LineLoader className={classes.lineLoader} />}
      <div className={classes.contentWrapper}>
        <Link to={Pages.home} className={classes.logoLink}>
          <LogoIcon className={classes.logoIcon} />
          <span className={classes.logoText}>Coindisco</span>
        </Link>
        {isMobileWidth ? (
          <MobileButton isLoadedAccount={isLoadedAccount} />
        ) : (
          <>
            <div className={classes.linkWrapper}>
              <Link className={classes.navLink} to={urlSelector.coins()}>
                Coins
              </Link>
              <Link className={classes.navLink} to={urlSelector.buy()}>
                Buy Crypto
              </Link>
              <Link className={classes.navLink} to={urlSelector.insights()}>
                Insights
              </Link>
              <Link className={classes.navLink} to={urlSelector.points()}>
                Points
              </Link>
            </div>
            <Buttons isLoadedAccount={isLoadedAccount} />
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
