import { FC, PropsWithChildren, ReactNode } from "react";
import { Links, Meta, Scripts, ScrollRestoration } from "@remix-run/react";

import globalClasses from "styles/classes.module.scss";

import useNonce from "hooks/use-nonce";

import Dialog from "components/dialog";

import Footer from "./components/footer";
import Splash from "./components/splash";

interface Props extends Required<PropsWithChildren> {
  head?: ReactNode;
}

const Template: FC<Props> = ({ head, children }) => {
  const nonce = useNonce();

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {head}
        <Meta />
        <Links />
      </head>
      <body className={globalClasses.splashOverflowHidden}>
        <Splash />
        {children}
        <Footer />
        <ScrollRestoration nonce={nonce} />
        <Dialog />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
};

export default Template;
