import { instance } from "./instances/coindisco";

interface CreateReviewDTO {
  transactionId: Transaction["transactionId"];
  text: string;
  rating: boolean;
  tags: string[];
}

const reviewsApi = new (class {
  public async createReview({ transactionId, ...args }: CreateReviewDTO) {
    const { data } = await instance.post(
      `service-integration/transactions/${transactionId}/reviews/`,
      args,
    );

    return data;
  }

  public async fetchTags(transactionId: Transaction["transactionId"]) {
    const { data } = await instance.get(
      `service-integration/transactions/${transactionId}/reviews/tags/`,
    );

    return data;
  }
})();

export default reviewsApi;
